export class TotalConverter {
  totalConverter = (data) => ({
    info: {
      credits: data.credits_sum,
      debits: data.debits_sum,
      total: data.total,
    },
    users: data.descendants.map(t => ({
      userId: t.user_id,
      username: t.username,
    }))
  });

  fullTotalConverter = (data) => ({
    info: {
      credits: data.credits_sum,
      debits: data.debits_sum,
      total: data.total,
    },
    users: data.descendants.map(t => ({
      userId: t.user_id,
      username: t.username,
      ...this.convertUser(t)
    }))
  });

  convertUser = data => ({
    credits: data.credits_sum,
    debits: data.debits_sum,
    total: data.total,
  });
}
